import { nanoid } from 'nanoid';
import * as yup from 'yup';

import { ExperienceLine } from 'store/v2/level/types';

export const MAX_CHARS = 109;
export const MAX_LINES_NUMBER = 4;

export const getDefaultExperienceLine: () => ExperienceLine = () => ({
  id: nanoid(6),
  value: '',
  enabled: true,
  isMerge: false,
  // the rest is for compatibility with old code
  chosen: false,
  high: false,
  isHighlighted: false,
  maxLength: MAX_CHARS,
  selected: false,
});

export const validationSchema = yup.object().shape({
  d1Athletics: yup.array().of(
    yup.object().shape({
      lines: yup
        .array()
        .of(
          yup.object().shape({
            value: yup
              .string()
              .test(
                'max-chars',
                `Each line must have at most ${MAX_CHARS} characters normally or ${MAX_CHARS * 2} if merged.`,
                function (val) {
                  const { isMerge } = this.parent;
                  const maxLimit = isMerge ? MAX_CHARS * 2 : MAX_CHARS;
                  return !val || val.length <= maxLimit;
                }
              ),
            enabled: yup.boolean(),
            isMerge: yup.boolean(),
          })
        )
        .test({
          name: 'max-lines',
          message: `You can add up to ${MAX_LINES_NUMBER} lines only.`,
          test: lines => Array.isArray(lines) && lines.length <= MAX_LINES_NUMBER,
        }),
    })
  ),
});
